.header {
  width: 100%;
  height: 100px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
  transition: all 0.3s ease;

  &.bg_white {
    background: #fff;
    box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);

    .page_header {
      @media screen and (max-width: 640px) {
        .mobile_nav {
          .btn {
            border-top: 2px solid #999;
            border-bottom: 2px solid #999;
            background-color: #999; } } }

      ul.nav {
        li {
          a {
            color: #B0B1B2;

            &:hover {
              color: #999; }

            &.active {
              color: #666; } } } }

      .search {
        background: rgba(161, 168, 178, 0.1);

        input {
          color: #333;

          &::-webkit-input-placeholder {
            color: #ccc; }

          &::-moz-placeholder {
            color: #ccc; }

          &:-ms-input-placeholder {
            color: #ccc; }

          &:-moz-placeholder {
            color: #ccc; } } }

      .login_btn {
        background: rgba(161, 168, 178, 0.1);
        color: #999;

        &:hover {
          color: #666; } } } }

  .page_header {
    width: 1200px;
    height: 100px;
    padding: 0 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .mobile_nav {
      display: none; }

    .logo {
      width: 160px;
      line-height: 0;

      img {
        width: 100%; } }

    ul.nav {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: 70px;

      li {
        a {
          padding: 10px 22px;
          font-size: 16px;
          line-height: 1;
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            color: #eee; }

          &.active {
            color: #fff; } } } }

    .search {
      width: 300px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.35);
      box-sizing: border-box;
      padding-left: 28px;

      input {
        flex: 1;
        font-size: 15px;
        background: transparent;
        color: #fff;

        &::-webkit-input-placeholder {
          color: #fff; }

        &::-moz-placeholder {
          color: #fff; }

        &:-ms-input-placeholder {
          color: #fff; }

        &:-moz-placeholder {
          color: #fff; } }

      .btn.search_icon {
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px; } } }

    .login_btn {
      margin-left: 26px;
      height: 48px;
      border-radius: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.35);
      padding: 0 28px;
      cursor: pointer;
      transition: all 0.2s ease;
      box-shadow: 0;
      color: #fff;
      position: relative;

      &:hover {
        color: #fff; } } }

  @media screen and (max-width: 1000px) {
    .page_header {
      ul.nav {
        margin-left: 30px;
        li {
          a {
            padding: 10px 16px;
            font-size: 15px; } } } } }

  @media screen and (max-width: 840px) {
    .page_header {
      .logo {
        width: 120px; }

      ul.nav {
        margin-left: 20px;

        li {
          a {
            padding: 10px;
            font-size: 14px; } } }

      .login_btn {
        margin-left: 10px;
        height: 38px;
        padding: 0 15px; } } }

  @media screen and (max-width: 660px) {
    height: 64px;

    .page_header {
      width: 100%;
      height: 64px;

      .logo {
        width: 106px; }

      ul.nav, .search, .login_btn {
        display: none; }

      .mobile_nav {
        display: block;

        .btn {
          width: 22px;
          height: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          background-color: #fff;
          padding: 5px 0;
          background-clip: content-box; }

        .fixed_con {
          position: fixed;
          width: 100vw;
          height: 100vh;
          left: -100vh;
          top: 0;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.2s ease;

          ul {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            padding: 30px 0;
            box-sizing: border-box;

            li {
              padding: 5px 20px;
              position: relative;
              left: -100%;
              transition: left 0.2s ease;

              &:nth-child(2) {
                transition-delay: 0.05s; }

              &:nth-child(3) {
                transition-delay: 0.1s; }

              &:nth-child(4) {
                transition-delay: 0.15s; }

              &:nth-child(5) {
                transition-delay: 0.2s; }

              &:nth-child(6) {
                transition-delay: 0.25s; }

              &:nth-child(7) {
                transition-delay: 0.3s; }

              a, .a {
                display: inline-block;
                padding: 10px 30px;
                background: #fff;
                border-radius: 40px;
                color: #666;
                font-size: 16px;

                &.active {
                  color: #aaa; } }

              .a {
                background: #fff;
                color: #666;
                opacity: 0.9;
                margin-top: 50px;
                cursor: pointer; } } }

          &.show {
            left: 0;
            opacity: 1;
            pointer-events: all;

            li {
              left: 0; } } } } } } }
