.lecture_alumnus_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(180deg, #F02D2D 0%, #FE6868 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before {
        display: block;
        content: '';
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 20px;
        top: 10px; }

      &:after {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 130px;
        top: 60px; }

      .icon {
        position: absolute;
        right: 100px;
        top: 20px;
        opacity: 0.6;

        img {
          width: 90px; } }

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } } }

  .card_list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.bg {
      background: #fff;
      border-radius: 10px;
      padding-bottom: 30px; }

    .card {
      flex: 0 0 260px;
      width: 260px;
      height: 210px;
      background: #FFFFFF;
      box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      transition: all 0.2s ease;

      &:not(:nth-child(4n)) {
        margin-right: 20px; }

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

      .headimg {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #eee;

        img {
          width: 100%;
          height: 100%; } }

      .name {
        font-size: 18px;
        color: #333333;
        margin-top: 24px; }

      .info {
        font-size: 14px;
        color: #999999;
        margin-top: 14px; } } }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    position: relative;
    z-index: 2; }

  @media screen and (max-width: 1200px) {
    .title_con {
      .info {
        padding: 0 20px;
        width: auto; } }

    .card_list {
      padding: 0 2%;

      .card {
        flex: 0 0 23%;
        width: 23%;
        margin-right: 0 !important;

        &:not(:nth-child(4n+1)) {
          margin-left: 2.5%; } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;

    .card_list {
      padding: 0 2%;

      .card {
        flex: 0 0 49%;
        width: 49%;
        margin-right: 0 !important;

        &:not(:nth-child(4n+1)) {
          margin-left: 0; }

        &:not(:nth-child(2n+1)) {
          margin-left: 2%; } } } } }
