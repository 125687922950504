.class_page {
  .header {
    display: none; }

  &.show_header {
    padding-top: 100px;

    .header {
      display: flex; }

    .page_title {
      box-shadow: none; }

    .content {
      &.mt {
        margin-top: 30px; } } }

  .content {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.mt {
      margin-top: 45px; }

    &.pd {
      padding-bottom: 100px; } }

  .page_title {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);

    .content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .l {
        height: 100px;
        display: flex;
        align-items: center;
        flex: 1;

        i.icon {
          display: inline-block;
          width: 26px;
          height: 28px;
          background: url('../../assets/img/icon_book.png') 0 0 no-repeat;
          background-size: 26px 28px; }

        span {
          font-size: 20px;
          color: #4E5653;
          line-height: 1; } }

      .back {
        width: 180px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #0F77FF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i.icon {
          display: inline-block;
          width: 16px;
          height: 15px;
          background: url('../../assets/img/icon_back.png') 0 0 no-repeat;
          background-size: 16px 15px; }

        span {
          margin-left: 14px;
          font-size: 15px;
          color: #0F77FF;
          line-height: 1; } } } }

  .class_preview {
    display: flex;
    justify-content: space-between;

    .imgs {
      flex: 0 0 660px;
      width: 660px;
      min-height: 100px;
      background: #FFFFFF;
      box-shadow: 0 5px 30px 0 rgba(29, 42, 67, 0.05);
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .img {
        width: 300px;
        line-height: 0;
        min-height: 100px;
        background: #f2f2f2;
        margin-top: 20px;

        &:nth-child(1), &:nth-child(2) {
          margin-top: 0; }

        img {
          width: 100%; } } }

    .download {
      flex: 0 0 220px;
      width: 220px;
      padding-top: 20px;

      .download_btn {
        // width: 160px
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #0F77FF;
        color: #fff;
        font-size: 15px;
        border-radius: 30px;
        cursor: pointer;

        &.line {
          margin-top: 10px;
          border: 1px solid #0F77FF;
          background: #fff;
          color: #0F77FF; } }

      .mobile_info {
        margin-top: 30px;
        display: flex;

        i {
          display: flex;
          justify-content: center;
          position: relative;
          top: 3px;
          flex: 0 0 17px;
          width: 17px;
          height: 17px;
          line-height: 1;
          border-radius: 50%;
          font-size: 12px;
          border: 1px solid #A1A8B2;
          color: #A1A8B2; }

        span {
          margin-left: 10px;
          font-size: 13px;
          line-height: 1.8;
          color: #A1A8B2; } }

      .info {
        display: flex;
        margin-top: 40px;

        i {
          display: flex;
          justify-content: center;
          position: relative;
          top: 3px;
          flex: 0 0 17px;
          width: 17px;
          height: 17px;
          line-height: 1;
          border-radius: 50%;
          font-size: 12px;
          border: 1px solid #A1A8B2;
          color: #A1A8B2; }

        span {
          margin-left: 10px;
          font-size: 13px;
          line-height: 1.8;
          color: #A1A8B2; } }

      .file_info {
        padding-top: 30px;
        margin-top: 40px;
        border-top: 1px solid #eee;

        p {
          line-height: 2;
          font-size: 14px;
          color: #A2A9B3; } } } }

  @media screen and (max-width: 1000px) {
    .content {
      width: 100%;

      &.mt {
        margin-top: 30px; }

      .class_preview {
        padding: 0 20px;
        flex-flow: row wrap;

        .imgs {
          width: 100%;
          flex: 0 0 auto;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between; }

        .img {
          width: 45%;

          img {
            width: 100%; } } } }

    .page_title {
      .content {
        padding: 0 20px; } } }

  @media screen and (max-width: 500px) {
    &.show_header {
      padding-top: 60px;

      .content {
        &.mt {
          margin-top: 20px; } } }

    .content {
      width: 100%;

      &.mt {
        margin-top: 30px; }

      .class_preview {
        flex-flow: column nowrap;

        .imgs {
          width: 100%;
          flex: 0 0 auto;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;

          .img:nth-child(2) {
            margin-top: 20px; }

          .img {
            width: 300px;

            img {
              width: 100%; } } }

        .download {
          // padding: 20px 30px
          flex: 1;
          width: 100%; }

        .file_info {
          margin-top: 30px; } } }

    .page_title {
      height: 64px;

      .content {
        padding: 0 20px;

        .l {
          height: 64px;

          i.icon {
            width: 18px;
            height: 18px;
            background-size: 18px 18px; }

          span {
            font-size: 16px;
            margin-left: 10px; } }

        .back {
          width: 28px;
          height: 28px;

          span {
            display: none; } } } } } }
