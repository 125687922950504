.recordcode {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: #F9F9F9;
  height: 60px;

  a {
    line-height: 60px;
    color: #A2A9B3; } }
