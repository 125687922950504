.follow_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    border-radius: 10px;

    .p_title {
      width: 960px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } } }

  .card_list {
    width: 960px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;

    .nodata {
      text-align: center;
      width: 100%; }

    .card {
      width: 300px;
      height: 330px;
      margin-bottom: 30px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0 10px 80px 0 rgba(29, 42, 67, 0.06);
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;
      margin-left: 30px;

      &:nth-child(3n+1) {
        margin-left: 0; }

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

      .img {
        line-height: 0;
        width: 260px;
        height: 146px;

        img {
          width: 260px;
          height: 146px; } }

      .title {
        font-size: 18px;
        line-height: 1.4;
        color: #4E5053;
        margin-top: 28px; }

      .info {
        font-size: 14px;
        line-height: 1.7;
        color: #A2A9B3;
        text-align: center;
        margin-top: 14px;
        max-width: 180px; }

      .bottom {
        width: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        .subscription {
          background: #0F77FF;
          border-radius: 18px;
          height: 34px;
          font-size: 12px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 15px;

          &.active {
            background: #bbb; }

          i.icon {
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 11px;
            background: url('../../assets/img/icon_subscription.svg') 0 0 no-repeat;
            background-size: 10px 11px; } } }

      .download {
        font-size: 13px;
        line-height: 1.5;
        color: #A2A9B3;
        display: flex;
        align-items: center;

        i.icon {
          display: inline-block;
          width: 18px;
          height: 12px;
          background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
          background-size: 18px 12px; }

        span {
          margin-left: 6px; } } } }

  @media screen and (max-width: 660px) {
    padding-top: 80px; } }
