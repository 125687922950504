.lecture_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .top_tabs {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    .tab {
      height: 88px;
      flex: 1;
      border-radius: 10px;
      line-height: 1;
      margin: 0 15px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0 20px;
      transition: all 0.2s ease;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:before {
        display: block;
        content: '';
        width: 116px;
        height: 116px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        left: -40px;
        top: 30px; }

      &:after {
        display: block;
        content: '';
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        left: 30px;
        top: 60px; }

      .img {
        position: absolute;
        left: 70%;
        top: 25%;
        opacity: 0.6; }

      .title {
        color: #fff;
        font-size: 20px;
        font-weight: bold; }

      .info {
        color: #fff;
        margin-top: 10px; }

      // &:nth-child(1)
      //   background-image: linear-gradient(180deg, #2F1DB0 0%, #5342C7 100%)

      // &:nth-child(2)
      //   background-image: linear-gradient(180deg, #142DFA 0%, #236EDF 100%)

      &:nth-child(1) {
        background-image: linear-gradient(180deg, #D93B06 0%, #FE6200 100%); }

      &:nth-child(2) {
        background-image: linear-gradient(180deg, #0371AF 0%, #22ADF6 100%); }

      &:nth-child(3) {
        background-image: linear-gradient(180deg, #F02C2C 0%, #FF6969 100%); }

      &:hover {
        // &:nth-child(1)
        //   box-shadow: 0 5px 11px 0 rgba(80, 63, 197, 0.47)

        // &:nth-child(2)
        //   box-shadow: 0 5px 11px 0 rgba(20, 45, 250, 0.47)

        &:nth-child(1) {
          box-shadow: 0 5px 11px 0 rgba(217, 59, 6, 0.47); }

        &:nth-child(2) {
          box-shadow: 0 5px 11px 0 rgba(3, 113, 175, 0.47); }

        &:nth-child(3) {
          box-shadow: 0 5px 11px 0 rgba(240, 44, 44, 0.47); } } } }

  .card {
    background: #FFFFFF;
    box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);
    border-radius: 8px;
    padding: 20px 40px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    &.mt {
      margin-top: 20px; }

    .title {
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        width: 50px;
        height: 24px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .lives {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row wrap;
      margin-top: 20px;

      .live {
        flex: 0 0 240px;
        width: 240px;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;
        padding-bottom: 10px;

        &:not(:nth-child(4n)) {
          margin-right: 20px;

          &:before {
            width: 260px; } }

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          width: 240px;
          top: 18px;
          border-top: 1px solid #eee; }

        &.notop {
          &:before {
            display: none; } }

        .icon {
          position: relative;
          z-index: 2;

          img {
            width: 36px;
            height: 36px; } }

        .date {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 10px; }

        .poster {
          width: 240px;
          height: 135px;
          background: #eee;
          line-height: 0;
          margin-top: 10px;
          border-radius: 10px;
          overflow: hidden;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%; } }

        .userinfo {
          height: 26px;
          font-size: 12px;
          color: #333333;
          display: flex;
          align-items: center;
          line-height: 1;
          width: 100%;
          padding-left: 15px;

          .headimg {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            background: #ddd;
            border: 1px solid #fff;
            margin-right: 10px;
            position: relative;
            top: -8px;

            img {
              width: 100%;
              height: 100%; } } }

        .live_title {
          width: 100%;
          margin-top: 10px;
          font-size: 16px;
          height: 18px;
          color: #333333;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }

        .live_info {
          width: 100%;
          margin-top: 6px;
          height: 16px;
          font-size: 14px;
          color: #999999;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }

        .status {
          width: 100%;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .btn {
            font-size: 14px;
            color: #FFFFFF;
            line-height: 1;
            padding: 5px 15px;
            display: inline-block;
            border-radius: 20px;
            background: #ccc;

            &.red {
              background: #D44242; }

            &.orange {
              background: #FC9301; }

            &.blue {
              background: #0F77FF;
 } } } } }              // padding: 8px 20px

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 20px; } }

  @media screen and (max-width: 1200px) {
    .top_tabs {
      .tab {
        .title {
          font-size: 16px; }

        .info {
          font-size: 12px; } } }

    .card {
      .title {
        span {
          font-size: 14px; }

        .icon {
          width: 22px;
          height: 22px;

          img {
            width: 100%;
            height: 100%; } } }

      .lives {
        flex-flow: row wrap;

        .live {
          flex: 0 0 23%;
          width: 23%;

          // &:not(:last-child)
          //   margin-right: 2.5%
          &:not(:nth-child(4n)) {
            margin-right: 2.5%; }

          .poster {
            width: 100%;
            height: auto; } } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;

    .top_tabs {
      flex-flow: row wrap;
      padding: 20px 0;

      .tab {
        flex: 0 0 90%;
        width: 90%;
        margin: 5px 5%;
        height: auto;
        padding: 15px 20px;

        .title {
          font-size: 16px; }

        .info {
          font-size: 12px; } } }

    .card {
      .title {
        span {
          font-size: 14px; }

        .icon {
          width: 22px;
          height: 22px;

          img {
            width: 100%;
            height: 100%; } } }

      .lives {
        flex-flow: row wrap;

        .live {
          flex: 0 0 100%;
          width: 100%;
          margin-right: 0 !important;
          margin-bottom: 20px;

          .poster {
            width: 100%;
            height: auto; } } } } } }
