.copyright_page {
  padding-top: 100px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
  .content {
    width: 960px;
    margin: 0 auto;
    min-height: 100px;
    padding: 60px 0;
    h1 {
      font-size: 20px;
      color: #A1A8B2;
      margin-top: 18px;
      line-height: 2; }
    p {
      font-size: 14px;
      color: #A1A8B2;
      line-height: 1.8;
      margin-top: 10px; }
    .p {
      display: flex;
      margin-top: 10px;
      .l {
        flex: 0 0 24px;
        width: 24px;
        font-size: 14px;
        color: #A1A8B2;
        line-height: 1.8; }
      .r {
        flex: 1;
        font-size: 14px;
        color: #A1A8B2;
        line-height: 1.8; } } }

  @media screen and (max-width: 1000px) {
    .content {
      width: 100%;
      padding: 40px 20px; } }
  @media screen and (max-width: 640px) {
    padding-top: 60px;
    .content {
      p {
        font-size: 13px; }
      .p {
        .r, .l {
          font-size: 13px; } } } } }
