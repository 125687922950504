.mall_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(49deg, #fc62a4 0%, #4219bd 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        color: #0F77FF;
        font-size: 14px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 10px 15px;
        cursor: pointer;
        line-height: 1; } } }

  .page_con {
    width: 100%;
    max-width: 1100px;
    display: flex;
    position: relative;
    z-index: 2;
    margin: 0 auto; }

  .page_card {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px 20px;
    width: 100%;
    min-height: 200px;

    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;

      .tab {
        line-height: 1;
        padding: 10px 20px;
        font-size: 16px;
        color: #999999;
        margin: 0 15px;
        cursor: pointer;

        &.active {
          background: #0F77FF;
          border-radius: 20px;
          color: #fff;
          cursor: default;
          pointer-events: none; } } }

    .m_cards {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      margin-top: 30px;

      &.video {
        .card {
          box-shadow: none;
          padding: 0;

          &:hover {
            box-shadow: none; }

          .img {
            width: 250px;
            height: 160px;
            background: #eee; } } }

      .card {
        flex: 0 0 250px;
        width: 250px;
        margin-bottom: 30px;
        border-radius: 10px;
        background: #FFFFFF;
        box-shadow: 0 10px 80px 0 rgba(29, 42, 67, 0.06);
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        cursor: pointer;
        padding: 16px;

        &:not(:nth-child(4n+1)) {
          margin-left: 20px; }

        &:hover {
          box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

        .img {
          width: 218px;
          // height: 218px
          line-height: 0;
          background: #eee;

          img {
            width: 100%;
 } }            // height: 100%

        .title {
          font-size: 18px;
          color: #4E5053;
          line-height: 1;
          margin-top: 20px;
          width: 100%; }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          line-height: 1;
          color: #999999;
          font-size: 14px;
          margin-top: 10px;

          span {
            font-size: 24px;
            color: #0F77FF; }

          .btn {
            background: #0F77FF;
            border-radius: 20px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding: 0 20px;
            line-height: 1; } } } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 0 20px; }

    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } }
    .page_card {
      .m_cards {
        align-items: center;
        .card {
          flex: 0 0 32.5%;
          width: 32.5%;
          margin-left: 0 !important;
          .img {
            width: 100%; } } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;
    .title_con {
      .info {
        .swiper {
          display: none; } } }
    .page_card {
      .m_cards {
        flex-flow: column nowrap;
        align-items: center;
        .card {
          flex: 0 0 100%;
          width: 100%;
          margin-left: 0 !important; } } } } }
