.book_page {
  .header {
    display: none; }
  .nodata {
    text-align: center; }
  &.show_header {
    padding-top: 100px;
    .header {
      display: flex; }
    .page_title {
      box-shadow: none; }
    .content {
      &.mt {
        margin-top: 20px; } } }
  .content {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    &.mt {
      margin-top: 45px; }
    &.pd {
      padding-bottom: 100px; } }
  .page_title {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0 10px 90px 0 rgba(29,42,67,0.05);
    .content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      .l {
        height: 100px;
        display: flex;
        align-items: center;
        flex: 1;
        i.icon {
          display: inline-block;
          width: 26px;
          height: 28px;
          background: url('../../assets/img/icon_book.png') 0 0 no-repeat;
          background-size: 26px 28px; }
        span {
          margin-left: 30px;
          font-size: 20px;
          color: #4E5653;
          line-height: 1; } }
      .back {
        width: 180px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #0F77FF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i.icon {
          display: inline-block;
          width: 16px;
          height: 15px;
          background: url('../../assets/img/icon_back.png') 0 0 no-repeat;
          background-size: 16px 15px; }
        span {
          margin-left: 14px;
          font-size: 15px;
          color: #0F77FF;
          line-height: 1; } } } }
  .unit {
    .unit_title {
      font-size: 20px;
      color: #4E5653;
      line-height: 1.7;
      padding-bottom: 20px;
      padding-top: 25px; }
    .classes {
      .class_mobile {
        display: none; }
      .class {
        padding: 14px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(161,168,178,0.20);
        cursor: pointer;
        background: #fff;
        transition: background .2s ease;
        &:hover {
          background: #f5f5f5; }
        .sort {
          flex: 0 0 68px;
          width: 68px;
          text-align: center;
          font-size: 16px;
          color: #A1A8B2; }
        .t {
          font-size: 16px;
          color: #4E5653;
          flex: 1; }
        .time {
          flex: 0 0 160px;
          width: 160px;
          color: #A1A8B2;
          font-size: 14px;
          margin-left: 30px;
          display: flex;
          align-items: center;
          .icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('../../assets/img/time_icon.png') 0 0 no-repeat;
            background-size: 16px 16px; }
          span {
            margin-left: 12px;
            line-height: 1; } }

        .download {
          flex: 0 0 160px;
          width: 160px;
          padding-right: 20px;
          color: #A1A8B2;
          font-size: 14px;
          display: flex;
          align-items: center;
          display: flex;
          align-items: center;
          .icon {
            display: inline-block;
            width: 20px;
            height: 14px;
            background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
            background-size: 20px 14px; }
          span {
            margin-left: 12px;
            line-height: 1; } } } } }
  @media screen and ( max-width: 500px ) {
    &.show_header {
      padding-top: 60px;
      .content {
        &.mt {
          margin-top: 20px; } } }
    .content {
      width: 100%;
      &.mt {
        margin-top: 30px; }
      .unit {
        padding: 0 20px;
        .unit_title {
          font-size: 14px;
          padding-bottom: 13px;
          padding-top: 17px; }
        .classes {
          .class {
            display: none; }

          .class_mobile {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            .sort {
              flex: 0 0 40px;
              width: 40px;
              font-size: 14px;
              color: #A1A8B2; }
            .r {
              flex: 1;
              border-bottom: 1px solid rgba(161,168,178,0.20);
              .t {
                line-height: 1.8;
                font-size: 16px;
                color: #4E5653;
                padding-top: 10px; }
              .b {
                display: flex;
                height: 40px;
                padding-bottom: 5px;
                .time {
                  color: #A1A8B2;
                  font-size: 12px;
                  margin-right: 25px;
                  display: flex;
                  align-items: center;
                  .icon {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: url('../../assets/img/time_icon.png') 0 0 no-repeat;
                    background-size: 12px 12px; }
                  span {
                    margin-left: 12px;
                    line-height: 1; } }

                .download {
                  padding-right: 20px;
                  color: #A1A8B2;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  display: flex;
                  align-items: center;
                  .icon {
                    display: inline-block;
                    width: 14px;
                    height: 10px;
                    background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
                    background-size: 14px 10px; }
                  span {
                    margin-left: 12px;
                    line-height: 1; } } } } } } } }
    .page_title {
      height: 64px;
      .content {
        padding: 0 20px;
        .l {
          height: 64px;
          i.icon {
            width: 18px;
            height: 18px;
            background-size: 18px 18px; }
          span {
            font-size: 16px;
            margin-left: 10px; } }
        .back {
          width: 28px;
          height: 28px;

          span {
            display: none; } } } } }

  @media screen and ( max-width: 1000px ) {
    .content {
      width: 100%;
      &.mt {
        margin-top: 30px; }
      .unit {
        padding: 0 20px; } }
    .page_title {
      .content {
        padding: 0 20px; } } } }
