.memessages_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    padding: 30px 20px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .content {
      max-width: 960px;
      width: 100%;
      margin: 30px auto 0;

      .tab {
        font-size: 16px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px; }

        .default {
          display: inline-block; }

        .active {
          display: none; } }

      .ant-tabs-tab-active {
        background-image: linear-gradient(269deg, rgba(15, 119, 255, 0.1) 0%, rgba(255, 255, 255, 0) 93%);

        .tab {
          color: #0F77FF;

          .default {
            display: none; }

          .active {
            display: inline-block; } } }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .l {
          font-size: 18px;
          color: #333333; }

        .r {
          font-size: 14px;
          color: #999999; } }

      .card {
        padding: 15px;
        border-radius: 10px;
        background: #f2f2f2;
        margin-top: 15px;

        &.active {
          .h1 {
            &:before {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              content: ' ';
              background: red; } } }

        .h1 {
          font-size: 16px;
          color: #333333;
          line-height: 1;
          display: flex;
          align-items: center; }

        .p {
          line-height: 1.7;
          font-size: 14px;
          color: #4F5157;
          margin-top: 10px; }

        .date {
          margin-top: 5px;
          font-size: 12px;
          color: #999999; } } } }

  @media screen and (max-width: 660px) {
    padding-top: 90px; } }
