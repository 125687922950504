.about_page {
  padding-top: 100px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;

  .content {
    width: 960px;
    margin: 0 auto;
    min-height: 100px;
    padding: 60px 0;
    position: relative;

    .introduction {
      display: flex;
      flex-flow: column nowrap;

      .img {
        width: 716px;
        height: 402px;
        position: relative;
        z-index: 2;
        border-radius: 3px;
        overflow: hidden;
        line-height: 0;

        img {
          width: 100%;
          height: 100%; } }

      .text {
        width: 868px;
        height: 430px;
        padding: 110px 40px 50px 50px;
        margin-top: -80px;
        position: relative;
        z-index: 1;
        align-self: flex-end;
        background: #F8F9FA;
        border-radius: 3px;
        overflow: hidden;

        p {
          font-size: 14px;
          color: #7D7D7D;
          line-height: 1.7;
          margin-top: 10px; } } }

    .title {
      text-align: center;
      font-size: 20px;
      color: #0F77FF;
      height: 64px;
      width: 218px;
      line-height: 64px;
      background: url('../../assets/img/about_us_title_bg.png') 0 0 no-repeat;
      background-size: 218px 64px;
      margin: 80px auto 0;

      &.no_mt {
        margin-top: 0; } }

    .title_small_info {
      color: #A1A8B2;
      font-size: 14px;
      text-align: center;
      margin-top: 15px; }

    .superiority {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      .card {
        background: #FFFFFF;
        box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);
        margin-top: 30px;
        flex: 0 0 460px;
        width: 460px;
        min-height: 250px;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        .icon {
          position: absolute;
          left: -14px;
          top: -13px;
          width: 100px;
          height: 100px;
          line-height: 0;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%; } }

        .t {
          padding: 45px 20px 0 110px;
          line-height: 1.5;
          font-size: 16px;
          color: #4E5053; }

        .p {
          width: 100%;
          margin-top: 30px;
          padding: 0 50px 30px;
          font-size: 14px;
          color: #A1A8B2;
          line-height: 1.8; } } }

    .influence {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding-top: 10px;

      .p {
        font-size: 16px;
        color: #4E5053;
        margin-top: 40px;

        span {
          display: inline-block;
          padding: 0 20px;
          position: relative;
          z-index: 2; }

        &::after {
          display: block;
          content: '';
          width: 100%;
          height: 8px;
          background: #FECB00;
          position: relative;
          top: -8px; } }

      .img {
        margin-top: 40px;
        width: 100%;
        line-height: 0;

        img {
          width: 100%; } } }

    .bottom_about {
      .info {
        color: #ABB1BB;
        font-size: 14px;
        text-align: center;
        margin-top: 50px; }

      .people {
        color: #636569;
        font-size: 14px;
        text-align: center;
        margin-top: 16px;

        span {
          font-size: 16px;
          color: #585A5E; } }

      .qrcode {
        width: 130px;
        height: 130px;
        margin: 0 auto;
        margin-top: 35px;
        line-height: 0;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%; } } }

    .swiper-container {
      margin-top: 70px; }

    .peoples {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .people {
        flex: 0 0 460px;
        width: 460px;

        .headimg {
          line-height: 0;
          width: 84px;
          height: 84px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #E0E0E0;

          img {
            width: 100%;
            height: 100%; } }

        .t {
          margin-top: 30px;
          margin-bottom: 25px;
          font-size: 16px;
          color: #4E5053; }

        p {
          font-size: 14px;
          color: #A1A8B2;
          line-height: 2.2; } } }

    .btn_list {
      position: absolute;
      z-index: 3;
      right: 20px;
      display: flex;

      .btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #A1A8B2;
        color: #A1A8B2;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        cursor: pointer;

        &.active {
          background: #2A86FE;
          border: 1px solid #2A86FE;
          color: #fff; } } } }

  .bg_gray {
    background: #F8F9FA; }

  @media screen and (max-width: 1000px) {
    .content {
      width: 100%;
      padding: 40px 20px;

      .introduction {
        .img {
          width: 100%;
          height: auto; }

        .text {
          width: 100%;
          height: auto;
          padding-left: 40px; } }

      .title {
        margin-top: 50px; } } }

  @media screen and (max-width: 960px) {
    .content {
      .superiority {
        justify-content: center; }

      .peoples {
        .people {
          flex: 1;
          width: auto;

          &:nth-child(2) {
            margin-left: 20px; } } } } }

  @media screen and (max-width: 640px) {
    .content {
      .introduction {
        .img {
          width: 100%;
          height: auto; }

        .text {
          width: 100%;
          height: auto;
          padding-left: 20px;
          padding-right: 20px;

          p {
            font-size: 13px; } } }

      .title {
        margin-top: 30px;
        transform: scale(0.7); }

      .superiority {
        flex-flow: column nowrap;

        .card {
          flex: 1;
          width: auto;

          .p {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 13px; } } }

      .influence {
        .p {
          text-align: center;
          font-size: 14px; } }

      .btn_list {
        display: none; }

      .peoples {
        flex-flow: column nowrap;

        &:not(:first-child) {
          margin-top: 40px; }

        .people {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;

          &:nth-child(2) {
            margin-left: 0;
            margin-top: 40px; }

          p {
            text-align: center;
            font-size: 13px;
            line-height: 1.6;
            margin-top: 10px; } } }

      .swiper-wrapper {
        flex-flow: column nowrap; }

      .bottom_about {
        .info {
          font-size: 13px; }

        .people {
          font-size: 13px;

          span {
            font-size: 14px; } } } } }

  @media screen and (max-width: 640px) {
    padding-top: 60px; } }
