.mehomework_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 30px 20px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      color: #333333;
      margin-top: 30px; }

    .user_work {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 35px;

      .userinfo {
        display: flex;
        align-items: center;

        .headimg {
          flex: 0 0 42px;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          position: relative;
          border: 1px solid #eee;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            background: #fff; }

          .b {
            position: absolute;
            bottom: -3px;
            height: 16px;
            background: #0F77FF;
            border-radius: 8px;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 8px 0 0;
            font-size: 12px;
            left: 50%;
            transform: translateX(-50%) scale(0.7);
            transform-origin: 50% 50%;

            span {
              border: 1px solid #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
              width: 18px;
              height: 18px;
              font-size: 12px; }

            i {
              padding-left: 4px; } } }

        .r {
          margin-left: 14px;

          .name {
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;

            span {
              background: #0F77FF;
              color: #fff;
              font-size: 12px;
              padding: 4px 5px;
              border-radius: 3px;
              margin-right: 3px;
              line-height: 1;
              transform: scale(0.8);
              transform-origin: 0 50%; }

            i {
              font-size: 12px;
              color: #999;
              margin-left: 5px; } }

          .date {
            font-size: 12px;
            color: #999999; } } }

      .homeworkinfo {
        margin-top: 15px;

        .txt {
          font-size: 14px;
          color: #4F5157;
          line-height: 1.7;
          white-space: pre-wrap; }

        .media_list {
          display: flex;
          margin-top: 8px;

          .img {
            width: 100px;
            height: 100px;
            background: #eee;
            border-radius: 10px;
            margin-right: 10px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; } } }
        .btns {
          display: flex;
          align-items: center; }

        .btn {
          border-radius: 7px;
          // width: 90px
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #333;
          background: #eee;
          border: 1px solid #bbb;
          margin-top: 20px;
          padding: 0 12px;
          cursor: pointer;

          span {
            color: #888; }

          .icon {
            display: inline-block;
            width: 19px;
            height: 17px;
            margin-right: 5px;
            background: url('../../assets/img/msg_icon_blue.svg') 0 0 no-repeat;
            background-size: 19px 17px; }

          &.like {
            margin-left: 10px;
            .icon {
              background: url('../../assets/img/icon_like.svg') 0 0 no-repeat;
              background-size: 19px 17px; } }

          &.active {
            color: #fff;
            background: #0E77FF;
            border: 1px solid #0E77FF;

            .icon {
              background: url('../../assets/img/msg_icon.svg') 0 0 no-repeat;
              background-size: 19px 17px; }

            span {
              color: #fff; } } }

        .review_box {
          margin-top: 10px;

          .input {
            width: 100%;
            padding: 15px;
            border-radius: 10px;
            background: #eee;
            font-size: 14px;
            color: #666;

            textarea {
              width: 100%;
              background: #eee;
              color: #333; } }

          .blue_btn {
            display: inline-block;
            padding: 8px 20px;
            background: #0F77FF;
            border-radius: 20px;
            font-size: 12px;
            color: #FFFFFF;
            font-size: 15px;
            margin-top: 10px;
            cursor: pointer;

            &.disabled {
              pointer-events: none;
              cursor: default;
              background: #999; } } }

        .leave_comments {
          background: #eee;
          border-radius: 10px;
          padding: 15px;
          margin-top: 15px;

          .submit_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            background: #fff;
            border-radius: 10px;

            .l {
              font-size: 12px;
              color: #999999; }

            .r {
              background: #0F77FF;
              border-radius: 15px;
              padding: 5px 15px;
              font-size: 12px;
              color: #FFFFFF;
              cursor: pointer; } }

          .comments {
            padding: 10px 0;

            .comment {
              display: flex;
              align-items: center;
              padding: 10px 0;

              .headimg {
                flex: 0 0 42px;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  background: #fff; } }

              .r {
                margin-left: 15px;

                .user {
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 14px;
                    color: #333333; }

                  img {
                    flex: 0 0 17px;
                    width: 17px;
                    height: 17px;
                    margin-left: 5px; }

                  i {
                    font-size: 12px;
                    color: #999999;
                    margin-left: 5px; } }

                .txt {
                  font-size: 14px;
                  color: #333333;
                  margin-top: 5px; } } } }

          .look_more {
            text-align: center;
            font-size: 14px;
            color: #0F77FF;
            padding-top: 10px;
            cursor: pointer;
            &.disabled {
              color: #999; } } } } }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 20px; } }

  @media screen and (max-width: 660px) {
    padding-top: 90px; } }
