.txt_box {
  .class_homwork {
    padding: 5px 0 10px;

    .h1 {
      font-size: 24px;
      color: #333333;
      line-height: 1.7;
      margin: 10px 0; }

    .h2 {
      font-size: 16px;
      line-height: 1.7;
      color: #666;
      margin: 10px 0;
      white-space: pre-wrap; } }

  .mt {
    margin-top: 20px; } }

.score_user_list {
  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    &.gary {
      .l {
        img {
          filter: grayscale(1); } } }
    &:first-child {
      .score {
        color: red; }
      .name {
        color: red; }
      .c {
        .t {
          color: red; }
        .city {
          color: red; } } }
    &:nth-child(2) {
      .score {
        color: orange; }
      .name {
        color: orange; }
      .c {
        .t {
          color: orange; }
        .city {
          color: orange; } } }
    &:nth-child(3) {
      .score {
        color: green; }
      .name {
        color: green; }
      .c {
        .t {
          color: green; }
        .city {
          color: green; } } }
    .l {
      flex: 0 0 25px;
      width: 25px;
      position: relative;
      img {
        width: 100%; }
      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        color: #888;
        z-index: 3; } }
    .avatar {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      line-height: 0;
      margin-left: 15px;
      border: 1px solid #eee;
      img {
        width: 100%; } }
    .name {
      flex: 0 0 100px;
      width: 100px;
      text-align: center; }
    .c {
      flex: 1;
      .t {
        font-size: 11px;
        color: #999; }
      .city {
        font-size: 12px;
        color: #666; } } } }
.me_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(179deg, #368EF1 0%, #82E7F9 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        color: #fff;
        font-size: 14px; } } }

  .page_con {
    width: 1100px;
    display: flex;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    .card {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 16px;
      width: 100%;

      &.gary {
        background: #f2f2f2; }

      &.blue_dot {
        background: #1D78F9;
        background-image: radial-gradient(rgba(255, 255, 255, 0.1) 1px, rgba(0, 0, 0, 0) 1px);
        background-size: 8px 8px; } }

    .mt {
      margin-top: 16px; }

    .sm_mt {
      margin-top: 12px; }

    .left_nav {
      flex: 0 0 350px;
      width: 350px;

      .user_info {
        display: flex;
        align-items: center;

        .head_img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          background: #eee;
          box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
          cursor: pointer;

          img {
            width: 100%;
            height: 100%; } }

        .info {
          margin-left: 15px;
          line-height: 1;
          cursor: pointer;

          .name {
            font-size: 16px;
            color: #333333; }

          .phone {
            font-size: 12px;
            color: #999999;
            margin-top: 10px; } } }

      .level_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .level {
          line-height: 1;

          .t {
            font-size: 24px;
            color: #333333;
            font-weight: bold; }

          .c {
            margin-top: 12px;
            font-size: 14px;
            color: #333333;

            span {
              cursor: pointer;
              color: #0F77FF;
              margin-left: 10px; } }

          .b {
            margin-top: 12px;
            font-size: 12px;
            color: #999999;
            display: flex;
            align-items: center;

            &.pointer {
              cursor: pointer; }

            i {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid #999999;
              width: 14px;
              height: 14px;
              margin-right: 3px; } } } }

      .nav {
        cursor: pointer;
        display: flex;
        align-items: center;

        .l_icon {
          flex: 0 0 100px;
          width: 100px;
          height: 75px;

          img {
            width: 100%;
            height: 100%; } }

        .r_name {
          margin-left: 22px;
          line-height: 1;

          .t {
            font-size: 18px;
            color: #333333; }

          .b {
            font-size: 12px;
            color: #999999;
            margin-top: 14px; } } } }

    .right_con {
      flex: 1;
      margin-left: 12px;
      position: relative;
      z-index: 2;

      .excuse {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          display: flex;
          align-items: center;

          img {
            width: 29px;
            height: 30px; }

          span {
            margin-left: 12px;
            font-size: 16px;
            color: #FFFFFF; } }

        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 114px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 20px;
          color: #1D78F9;
          font-size: 16px; } }

      .big_title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 1.5; }

      .small_title {
        font-size: 16px;
        color: #333333;
        line-height: 1;
        margin-top: 30px; }

      .tabs {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .tab {
          cursor: pointer;
          line-height: 2;
          margin-right: 20px;
          font-size: 14px;
          color: #999;

          &.active {
            color: #1F80FF;
            border-bottom: 2px solid #1F80FF; } } }

      .task {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .l {
          line-height: 1.6;
          flex: 1;

          .title {
            font-size: 16px;
            color: #333333; }

          .info {
            font-size: 14px;
            color: #999999;
            margin-top: 4px; } }

        .btn {
          flex: 0 0 90px;
          text-align: center;
          cursor: pointer;
          padding: 10px 20px;
          color: #fff;
          border-radius: 24px;
          font-size: 14px;
          line-height: 1;
          margin-left: 10px;

          &.red {
            background: #FF6800; }

          &.blue {
            background: #1D78F9; }

          &.disabled {
            background: #aaa;
            pointer-events: none; } } } }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 20px;

      span {
        color: #999;
        &.blue {
          cursor: pointer;
          color: #0F77FF; } } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 0 20px; }

    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;

    .page_con {
      flex-flow: column nowrap;

      .left_nav {
        flex: 1;
        width: auto;

        .level_box {
          justify-content: flex-start;

          .level {
            margin-right: 10px; } } }

      .right_con {
        flex: 1;
        width: auto;
        margin-left: 0;
        margin-top: 20px;

        .excuse {
          .btn {
            font-size: 14px;
            width: 104px; }
          .name {
            span {
              font-size: 12px; } } }
        .tabs {
          .tab {
            margin-right: 12px;
            font-size: 13px; } }

        .task {
          flex-flow: column nowrap;
          align-items: flex-start;

          .btn {
            flex: 0;
            margin-top: 10px;
            margin-left: 0; } } } } } }
