.lectureplayer_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;
  .ant-upload-disabled {
    img {
      filter: grayscale(1); } }
  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding-bottom: 30px;
    border-radius: 10px;
    // display: flex

    .card {
      background: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      overflow: hidden;

      &.no_pd {
        padding: 0; }
      .leave_comments {
        background: #eee;
        border-radius: 10px;
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 15px; }
      .comments {
        padding: 10px 0;

        .look_more {
          text-align: center;
          font-size: 14px;
          color: #0F77FF;
          padding-top: 10px;
          cursor: pointer;
          &.disabled {
            color: #999; } }
        .comment {
          display: flex;
          align-items: center;
          padding: 10px 0;

          .headimg {
            flex: 0 0 42px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              background: #fff; } }

          .r {
            margin-left: 15px;

            .user {
              display: flex;
              align-items: center;

              span {
                font-size: 14px;
                color: #333333; }

              img {
                flex: 0 0 17px;
                width: 17px;
                height: 17px;
                margin-left: 5px; }

              i {
                font-size: 12px;
                color: #999999;
                margin-left: 5px; } }

            .txt {
              font-size: 14px;
              color: #333333;
              margin-top: 5px; } } } } }

    .player_box {
      flex: 1;

      .teach_info {
        display: flex;
        align-items: center;
        padding: 15px;

        .headimg {
          flex: 0 0 60px;
          width: 60px;
          height: 60px;
          border: 1px solid #eee;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%; } }

        .name {
          flex: 1;
          margin-left: 15px;

          .t {
            font-size: 18px;
            color: #333333;
            display: flex;
            align-items: center; }

          .i {
            font-size: 14px;
            color: #999999;
            display: flex;
            align-items: center;
            line-height: 0;
            margin-top: 5px;

            img {
              margin-right: 5px;
              width: 18px;
              height: 18px; } } }

        .sign {
          height: 24px;
          padding: 0 20px;
          cursor: pointer;
          background: #0F77FF;
          border: 1px solid #0F77FF;
          border-radius: 12px;
          font-size: 12px;
          color: #fff;
          line-height: 24px;
          text-align: center;
          margin-left: 14px;
          display: flex;
          align-items: center;
          &.bounce {
            animation: bounce 1s ease; }
          &.disabled {
            pointer-events: none;
            background: #aaa;
            border: 1px solid #aaa; }
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px; } }

        .back {
          height: 24px;
          padding: 0 20px;
          cursor: pointer;
          background: #F7F7F7;
          border: 1px solid #CDCDCD;
          border-radius: 12px;
          font-size: 12px;
          color: #999999;
          line-height: 24px;
          text-align: center;
          margin-left: 14px; } }

      .video_ctx {
        width: 100%;
        min-height: 200px;
        background: #ddd;
        .prism-player {
          padding-bottom: 56.25%; } }
      .cover_image {
        width: 100%;
        img {
          width: 100%;
          height: auto; } }
      .begin_start {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 60px 20px;
        img {
          width: 70%;
          max-width: 400px; }
        span {
          color: #666;
          margin-top: 50px; } }

      .transcoding {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        background: #555;
        background: -webkit-linear-gradient(top,#666,#555);
        background: -ms-linear-gradient(top,#666,#555);
        background: -o-linear-gradient(top,#666,#555);
        background: -moz-linear-gradient(top,#666,#555);

        .box {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          padding-bottom: 5%;
          .title {
            color: #fff;
            font-size: 28px;
            line-height: 1;
            position: relative;
            text-align: center;
            &::before {
              position: absolute;
              left: 0;
              top: -24px;
              display: block;
              content: '“';
              color: #fff;
              font-size: 48px;
              line-height: 1; }
            &::after {
              position: absolute;
              right: 0;
              top: 28px;
              display: block;
              content: '”';
              color: #fff;
              font-size: 48px;
              line-height: 1; } }
          .info {
            font-size: 14px;
            color: #999999;
            margin-top: 30px; } } }

      .tip_box {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        background: #666;

        .live {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          img {
            width: 100%;
            height: 100%; } }

        .tip {
          color: #fff;
          font-size: 14px;
          position: absolute;
          top: 10px;
          width: 100%;
          text-align: center; }

        .subject {
          position: absolute;
          padding-top: 20px;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .l {
            flex: 0 0 40%;
            width: 40%;

            .pc {
              width: 100%;
              display: flex;
              flex-flow: column nowrap;
              align-items: center;

              &.mt {
                margin-top: 20px; }

              .img {
                width: 100%;
                height: auto;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

                img {
                  width: 100%; } }

              .p {
                color: #fff;
                font-size: 12px;
                line-height: 2;
                margin-top: 5px; } } }

          .r {
            flex: 0 0 40%;
            width: 40%;
            margin-left: 10px;

            .phone {
              width: 100%;
              display: flex;
              flex-flow: column nowrap;
              align-items: center;

              .img {
                width: 45%;
                height: auto;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

                img {
                  width: 100%; } }

              .p {
                color: #fff;
                font-size: 12px;
                line-height: 2;
                margin-top: 5px; } } } } } }

    .p_title {
      line-height: 60px;
      font-size: 20px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        cursor: pointer;
        background: #fff;
        border: 1px solid #CDCDCD;
        border-radius: 15px;
        font-size: 12px;
        color: #999999;
        text-align: center;
        margin-left: 14px; } }

    .class_info {
      padding: 5px 0;
      display: flex;
      align-items: center;

      .img {
        flex: 0 0 208px;
        width: 208px;
        height: 140px;
        background: #eee;

        img {
          width: 100%;
          height: 100%; } }

      .r {
        flex: 1;
        margin-left: 20px;

        .t {
          font-size: 16px;
          color: #333333;
          line-height: 2; }

        .b {
          font-size: 14px;
          color: #999999;
          line-height: 1.6; } } }

    .class_homwork {
      padding: 5px 0 10px;

      .h1 {
        font-size: 24px;
        color: #333333;
        line-height: 1.7;
        margin: 10px 0; }

      .h2 {
        font-size: 16px;
        line-height: 1.7;
        color: #999;
        margin: 10px 0;
        white-space: pre-wrap; } }

    .mt {
      margin-top: 20px; }

    .message_box {
      flex: 0 0 220px;
      width: 220px;
      margin-left: 20px;

      // display: none
      .title {
        font-size: 16px;
        line-height: 1.7;
        color: #333333; }

      .msgs {
        height: 360px; }

      .bottom {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 5px; } } } }

  @media screen and (max-width: 1140px) {
    .page_con {
      padding: 20px; } }

  @media screen and (max-width: 960px) {
    .page_con {
      .message_box {
        display: none; }

      .player_box {
        .transcoding {
          .box {
            .title {
              font-size: 14px;
              &::after {
                top: 14px; } } } }
        .card {
          .class_info {
            flex-flow: column nowrap;
            align-items: flex-start;

            .img {
              flex: 0 0 auto; }

            .r {
              margin-left: 0; } } }

        .tip_box {
          .tip {
            top: 5px;
            font-size: 10px; }

          .subject {
            .l {
              .pc {
                &.mt {
                  margin-top: 5px; }

                .p {
                  line-height: 1.5;
                  font-size: 10px; } } }

            .r {
              .phone {
                .p {
                  line-height: 1.5;
                  font-size: 10px; } } } } } } } }

  @media screen and (max-width: 660px) {
    padding-top: 90px;

    .page_con {
      padding: 0 5px;
      .player_box {
        .teach_info {
          position: relative;
          .sign {
            position: absolute;
            right: 10px;
            bottom: 17px; }
          .back {
            position: absolute;
            right: 10px;
            top: 15px; } } } } } }
