.certificate_page {
  padding-top: 100px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;

  .banner {
    width: 100%;
    position: relative;
    height: 420px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%; }

    .img_con {
      width: 1920px;
      height: 420px;
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%); }

    .txt_box {
      position: absolute;
      z-index: 3;
      width: 620px;
      left: 50%;
      top: 130px;
      transform: translateX(-50%);
      transform-origin: center center;

      p {
        font-size: 18px;
        line-height: 1.7;
        color: #e48a41; }

      h1 {
        font-size: 24px;
        line-height: 1.7;
        color: #e48a41;
        margin-top: 20px; } }

    .bg {
      width: 120%;
      height: 120%;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: -10%;
      transform: translateX(-50%);
      filter: blur(20px); } }

  .content {
    width: 960px;
    margin: 0 auto;
    min-height: 100px;
    padding: 60px 0;

    &.bg {
      width: 100%;
      background: #F8F9FA; }

    .search {
      background: #fff;
      width: 440px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      box-shadow: 0 0 40px 0 rgba(29, 42, 67, 0.08);
      border-radius: 10px;
      overflow: hidden;

      .input {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        padding: 0 10px;

        .icon {
          flex: 0 0 17px;
          width: 17px;
          height: 17px;
          background: url('../../assets/img/search_icon_2.png') 0 0 no-repeat;
          background-size: 17px 17px; }

        input {
          flex: 1;
          margin-left: 10px;
          font-size: 15px; } }

      .btn {
        flex: 0 0 80px;
        width: 80px;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 15px;
        background: #0F77FF; } }

    .search_info {
      width: 960px;
      margin: 30px auto 0;

      .t {
        font-size: 15px;
        color: #ADADAD;
        width: 100%;

        // text-align: center
        span {
          color: #0F77FF; } }

      .nodata {
        width: 100%;
        padding: 55px 0 10px;
        text-align: center;
        font-size: 18px;
        color: #666666; }

      .img_card {
        padding: 30px 0 0;

        .img, img {
          width: 100%; }

        .btn {
          margin: 0 auto;
          background: #0F77FF;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 48px;
          border-radius: 24px;
          margin-top: 20px;
          cursor: pointer;

          .icon {
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            background: url('../../assets/img/zoom.png') 0 0 no-repeat;
            background-size: 16px 16px; }

          .span {
            font-size: 15px;
            color: #FFFFFF;
            margin-left: 10px; } } } } }

  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
    line-height: 30px;
    padding-bottom: 40px;

    &.bt {
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid #eee; } }

  p {
    font-size: 14px;
    color: #A1A8B2;
    line-height: 1.8;
    &.indent {
      text-indent: 28px; }

    span {
      color: #333; } }

  .qr_bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
    margin-top: 60px;
    border-top: 1px solid #eee;

    .qr_box {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      .img {
        width: 150px;
        height: 150px;

        img {
          width: 100%;
          height: 100%; } }

      span {
        margin-top: 20px;
        font-size: 14px;
        color: #A1A8B2; } } }

  @media screen and (max-width: 1000px) {
    .content {
      width: 100%;
      padding: 40px 20px;

      .search_info {
        width: 100%; } } }

  @media screen and (max-width: 640px) {
    padding-top: 60px;

    .banner {
      height: 220px;

      .img_con {
        width: 1006px;
        height: 220px; }

      .txt_box {
        top: 20px;
        left: 50%;
        transform: translateX(-50%) scale(0.52); } }

    .content {
      width: 100%;

      .search {
        width: 300px; }

      .search_info {
        width: 100%; }

      p {
        font-size: 13px; } } } }
