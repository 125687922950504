.press_page {
  .header {
    display: none; }

  &.show_header {
    padding-top: 100px;

    .header {
      display: flex; }

    .page_title {
      box-shadow: none; } }

  .content {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }

  .form {
    display: flex;
    align-items: center;
    padding-top: 30px;

    .label {
      font-size: 18px;
      color: #4E5053; }

    .select {
      margin-left: 20px;
      width: 180px;
      border: 1px solid rgba(161, 168, 178, 0.3);
      border-radius: 4px;
      height: 46px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      position: relative;
      background: rgba(161, 168, 178, 0.1);

      select {
        padding-right: 20%;
        box-sizing: border-box;
        cursor: pointer;
        flex: 1;
        max-width: 235px;
        line-height: 1;
        font-size: 14px;
        background: transparent;
        outline: none;
        color: #555;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;
        -webkit-tap-highlight-color: #fff;
        background: transparent;
        border: 0; }

      .right_icon {
        position: absolute;
        right: 5px;
        flex: 0 0 20px;
        width: 20px;
        display: flex;
        align-items: center;

        .icon {
          width: 0;
          height: 0;
          border-top: 6px solid #7D7E81;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent; } } } }

  .card_list {
    width: 960px;
    margin: 0 auto;
    margin-top: 55px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .card {
      width: 300px;
      height: 240px;
      margin-bottom: 30px;
      margin-left: 30px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0 10px 80px 0 rgba(29, 42, 67, 0.06);
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;

      &:nth-child(3n + 1) {
        margin-left: 0; }

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

      .img {
        line-height: 0;
        width: 260px;
        height: 146px;

        img {
          width: 260px;
          height: 146px; } }

      .title {
        font-size: 18px;
        line-height: 1.4;
        color: #4E5053;
        margin-top: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 90%; } } }

  @media screen and (max-width: 640px) {
    &.show_header {
      padding-top: 60px; }

    .content {
      width: 100%;

      .form {
        padding-top: 10px;

        .label {
          display: none; }

        .select {
          margin-left: 10px;
          margin-right: 10px; } }

      .card_list {
        width: 100%;
        margin-top: 30px;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center; } } } }
