.choice_line {
  position: absolute;
  bottom: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
  color: #fff;
  font-size: 15px;

  &.select {
    span {
      &::after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } } }

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 10px;
    position: relative; } }

.download_page {
  padding-top: 100px;
  min-height: 100vh;
  position: relative;
  padding-bottom: 60px;

  .home_title {
    width: 960px;
    margin: 0 auto;
    line-height: 1;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .l {
      font-size: 26px;
      color: #A1A8B2;
      flex: 1; }

    .search_bar {
      flex: 0 0 320px;
      width: 320px;
      background: #F4F4F4;
      border-radius: 22px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 20px;

      .input {
        flex: 1;

        input {
          width: 100%;
          background: transparent;
          font-size: 18px;
          color: #666; } }

      .btn {
        flex: 0 0 80px;
        width: 80px;
        background: #0F77FF;
        border-radius: 17px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;

        .icon {
          margin-right: 5px;

          img {
            width: 15px;
            height: 15px; } } } } }

  .card_list {
    width: 960px;
    margin: 0 auto;
    margin-top: 55px;
    display: flex;
    flex-flow: row wrap;

    .nodata {
      text-align: center;
      width: 100%; }

    .card {
      width: 300px;
      height: 330px;
      margin-bottom: 30px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0 10px 80px 0 rgba(29, 42, 67, 0.06);
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;
      margin-left: 30px;

      &:nth-child(3n+1) {
        margin-left: 0; }

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

      .img {
        line-height: 0;
        width: 260px;
        height: 146px;

        img {
          width: 260px;
          height: 146px; } }

      .title {
        font-size: 18px;
        line-height: 1.4;
        color: #4E5053;
        margin-top: 28px; }

      .info {
        font-size: 14px;
        line-height: 1.7;
        color: #A2A9B3;
        text-align: center;
        margin-top: 14px;
        max-width: 180px; }

      .bottom {
        width: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        .subscription {
          background: #0F77FF;
          border-radius: 18px;
          height: 34px;
          font-size: 12px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 15px;

          &.active {
            background: #bbb; }

          i.icon {
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 11px;
            background: url('../../assets/img/icon_subscription.svg') 0 0 no-repeat;
            background-size: 10px 11px; } } }

      .download {
        font-size: 13px;
        line-height: 1.5;
        color: #A2A9B3;
        display: flex;
        align-items: center;

        i.icon {
          display: inline-block;
          width: 18px;
          height: 12px;
          background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
          background-size: 18px 12px; }

        span {
          margin-left: 6px; } } } }

  .bottom_count {
    width: 960px;
    height: 240px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .digital {
      display: flex;
      align-items: center;

      .l {
        width: 66px;
        height: 66px;
        line-height: 0;

        img {
          width: 100%;
          height: 100%; } }

      .r {
        margin-left: 24px;

        .number {
          font-size: 22px;
          line-height: 1.5;
          color: #A1A8B2; }

        .info {
          font-size: 13px;
          line-height: 1.5;
          color: #A1A8B2; } } } }

  @media screen and (max-width: 1000px) {
    .card_list {
      width: 100%;
      padding: 0 10px;

      .card {
        flex: 0 0 30%;
        width: 30%;
        padding: 20px 0;
        height: auto;

        .img {
          width: 94%;
          height: auto;

          img {
            width: 100%;
            height: auto; } } } }

    .bottom_count {
      width: 100%;
      padding: 20px 0;
      height: auto;
      justify-content: space-around;

      .digital {
        flex-flow: column nowrap;
        margin-top: 40px;

        .r {
          margin-left: 0;
          margin-top: 20px;
          text-align: center; } } }

    .home_title {
      width: 100%;
      font-size: 22px; } }

  @media screen and (max-width: 680px) {
    .home_title {
      flex-flow: column nowrap;

      .l {
        font-size: 18px;
        text-align: center; }

      .search_bar {
        margin-top: 30px;
        flex: 0 0 auto; } }

    .card_list {
      .card {
        margin-left: 20px; } } }

  @media screen and (max-width: 640px) {
    .home_title {
      width: 100%;
      font-size: 16px; }

    .card_list {
      padding: 0;
      width: 100%;
      justify-content: center;

      .card {
        flex: 0 0 300px;
        width: 300px;
        padding: 20px 0;
        height: auto;
        margin-left: 0;

        .img {
          width: 260px;
          height: auto;

          img {
            width: 100%;
            height: auto; } } } }

    .bottom_count {
      width: 100%;
      flex-flow: column nowrap;
      padding: 20px 0;
      height: auto;

      .digital {
        flex-flow: column nowrap;
        margin-top: 40px;

        .r {
          margin-left: 0;
          margin-top: 20px;
          text-align: center; } } } }

  .banner {
    width: 100%;
    position: relative;
    height: 420px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%; }

    .img_con {
      width: 1920px;
      height: 420px;
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%); }

    .bg {
      width: 120%;
      height: 120%;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: -10%;
      transform: translateX(-50%);
      filter: blur(20px); } }

  @media screen and (max-width: 640px) {
    padding-top: 60px;

    .banner {
      height: 320px;

      .img_con {
        width: 1463px;
        height: 320px; } } } }
