.video-ctx {
  .prism-player {
    padding-bottom: 56.25%; } }

.mallgoodinfo_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .con {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0 20px; }

    .top_info {
      display: flex;
      width: 100%;

      .goodimgs {
        float: 0 0 360px;
        width: 360px;
        height: 360px;

        .swiper-container {
          width: 100%;
          height: 100%;
          background: #eee; }

        .goodimg {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eee;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .play_icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            cursor: pointer; } } }

      .right_options {
        flex: 1;
        margin-left: 50px;
        line-height: 1;

        .title {
          font-size: 18px;
          color: #333333;
          padding-top: 5px; }

        .titile_desc {
          font-size: 14px;
          color: #999999;
          margin-top: 14px;
          padding-bottom: 16px;
          border-bottom: 1px solid #eee;
          line-height: 1.5; }

        .parameter_title {
          font-size: 14px;
          color: #333333;
          margin-top: 18px; }

        .parameters {
          display: flex;
          flex-flow: row wrap;
          margin-top: 16px;

          .parameter {
            font-size: 12px;
            color: #999999;
            margin-top: 18px;

            &:nth-child(3n + 1) {
              flex: 0 0 42%;
              width: 42%; }

            &:nth-child(3n + 2) {
              flex: 0 0 32%;
              width: 32%; }

            &:nth-child(3n + 3) {
              flex: 0 0 26%;
              width: 26%; } } }

        .buy_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 30px;

          .credit {
            flex: 1;

            span {
              font-size: 30px;
              color: #0F77FF; } }

          .info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .txt {
              margin-left: 8px; } } }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 40px;

          .btn {
            background: #0F77FF;
            border-radius: 24px;
            height: 48px;
            flex: 0 0 140px;
            width: 140px;
            font-size: 16px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            left: -4px;
            cursor: pointer; }

          .txt {
            font-size: 14px;
            color: #777777;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .span {
              color: #0F77FF;
              cursor: pointer;
              display: inline; } } } } }

    .tabs {
      margin-top: 20px;
      padding-bottom: 50px; }
    .img {
      width: 100%;
      line-height: 0;
      img {
        width: 100%; } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 20px;
      .p_title {
        span {
          margin-left: 0; } }
      .con {
        padding: 0;
        .top_info {
          .right_options {
            .buy_info {
              flex-flow: column nowrap;
              align-items: flex-start;
              width: 100%;
              .info {
                margin-top: 15px; } } } } } }

    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } } }

  @media screen and (max-width: 660px) {
    padding-top: 90px;
    .page_con {
      .con {
        .top_info {
          flex-flow: column nowrap;
          .goodimgs {
            flex: 0 0 100%;
            width: 100%;
            height: auto; }
          .right_options {
            margin-left: 0;
            margin-top: 15px;
            .parameters {
              display: flex;
              flex-flow: row wrap;
              margin-top: 16px;

              .parameter {
                font-size: 12px;
                color: #999999;
                margin-top: 18px;
                flex: 0 0 100% !important;
                width: 100% !important; } }

            .buy_info {
              flex-flow: column nowrap;
              align-items: flex-start;
              width: 100%;
              .info {
                margin-top: 10px; } }

            .bottom {
              flex-flow: row wrap;
              .txt {
                flex: 0 0 100%;
                width: 100%;
                justify-content: flex-start;
                margin-top: 10px; } } } } } } } }
