$loginHeight: 540px;
$infomationHeight: 690px;
.login_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;
  &.show {
    pointer-events: auto;
    .bg {
      opacity: 1; }
    .card {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(0); } }
  .bg {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease;
    cursor: pointer; }
  .card {
    width: 800px;
    display: flex;
    min-height: 100px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    overflow: hidden;
    left: 70%;
    top: -$loginHeight - 10px;
    transform: translate(-50%, 0) rotate(-45deg);
    transform-origin: 0 100%;
    z-index: 2;
    transition: all .3s ease;
    .close_btn {
      position: absolute;
      width: 40px;
      height: 46px;
      background: rgba(161,168,178,0.20);
      top: 0;
      right: 17px;
      padding-bottom: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px; } }
    .l {
      flex: 0 0 430px;
      width: 430px;
      height: $loginHeight;
      overflow: hidden;
      background-image: linear-gradient(-43deg, #0A1971 0%, #60237E 100%);
      display: flex;
      align-items: center;
      // justify-content: center
      img {
        object-fit: cover;
        height: 100%; } }
    .form {
      flex: 1;
      min-height: $loginHeight;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      box-sizing: border-box;
      padding: 36px 55px;
      .logo_square {
        width: 140px;
        height: auto;
        line-height: 0;
        img {
          width: 100%;
          height: 100%; } }
      .title {
        color: #4E5053;
        font-size: 22px;
        line-height: 1.7;
        margin-top: 20px;
        margin-bottom: 30px; }
      .textarea {
        width: 100%;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px;
        border-radius: 20px;
        margin-top: 20px;
        textarea {
          flex: 1;
          height: 200px;
          font-size: 14px;
          line-height: 1.8;
          resize: none; } }
      .input {
        width: 100%;
        border-bottom: 1px solid #eee;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        select {
          flex: 1;
          max-width: 235px;
          line-height: 1;
          font-size: 14px;
          box-sizing: border-box;
          background: transparent;
          border: 0;
          outline: none;
          color: #555;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          outline: none;
          -webkit-tap-highlight-color: #fff;
          background: transparent;
          border: 0; }
        input {
          flex: 1; }
        .right_icon {
          position: absolute;
          right: 0;
          flex: 0 0 20px;
          width: 20px;
          display: flex;
          align-items: center;
          .icon {
            width: 0;
            height: 0;
            border-top: 6px solid #7D7E81;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent; } }
        .btn {
          font-size: 12px;
          line-height: 1;
          color: #0F77FF;
          border: 1px solid #0F77FF;
          padding: 8px 18px;
          border-radius: 20px;
          cursor: pointer;
          &.gray {
            color: #ccc;
            border: 1px solid #ccc; } } }
      .submit {
        margin-top: 35px;
        font-size: 14px;
        line-height: 1;
        padding: 14px 36px;
        color: #fff;
        background: #0F77FF;
        align-self: flex-start;
        border-radius: 30px;
        cursor: pointer;
        &.center {
          align-self: center; } }

      .agreement {
        width: 100%;
        margin-top: 5px;
        padding: 5px;
        font-size: 12px;
        cursor: pointer;
        span {
          color: #0F77FF; } } } }

  &.commit {
    .card {
      height: 500px; } }
  &.perfect_infomation {
    &.show {
      .card {
        left: 50%;
        top: 50%; } }
    .card {
      top: -$infomationHeight - 10px;
      .l {
        height: $infomationHeight; }
      .form {
        min-height: $infomationHeight;
        .input_t {
          align-self: flex-start;
          margin-top: 18px; }
        .ant-cascader-picker {
          margin-top: 5px;
          .ant-input {
            margin-top: 0; } }
        .ant-input {
          margin-top: 5px; }
        .input {
          margin-top: 10px;
          border: 1px solid rgba(161,168,178,0.30);
          height: 46px;
          border-radius: 4px;
          padding: 0 10px; }
        .selects {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          .input {
            flex: 0 0 48%;
            width: 48%;
            background: rgba(161,168,178,0.10);
            select {
              width: 100%;
              height: 100%;
              padding-right: 20%;
              box-sizing: border-box;
              cursor: pointer; } } } } } }
  @media screen and ( max-width: 820px ) {
    &.perfect_infomation .card,
    .card {
      width: 600px;
      .l {
        flex: 0 0 200px;
        width: 200px; } } }
  @media screen and ( max-width: 620px ) {
    &.commit {
      .card {
        min-height: 400px; } }
    &.perfect_infomation .card,
    .card {
      width: 450px;
      .l {
        flex: 0 0 0;
        width: 0;
        height: 450px; }
      .form {
        min-height: 450px;
        .logo_square {
          display: none; } } }
    &.perfect_infomation .card {
      .l {
        height: 550px; }
      .form {
        min-height: 550px; } } }
  @media screen and ( max-width: 470px ) {
    &.commit {
      .card {
        height: 360px; } }
    &.perfect_infomation .card,
    .card {
      width: 300px;
      .l {
        flex: 0 0 0;
        width: 0;
        height: 300px; }
      .form {
        padding: 20px;
        min-height: 300px;
        .logo_square {
          display: none; }
        .title {
          margin: 0;
          line-height: 1;
          font-size: 18px; }
        .selects {
          .input {
            height: 35px; } }
        .submit {
          margin-top: 20px; } } }
    &.perfect_infomation .card {
      .l {
        height: 400px; }
      .form {
        min-height: 400px; } } } }
