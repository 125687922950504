.dream_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(0, #2e4975 0%, #001532 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before {
        display: block;
        content: '';
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 20px;
        top: 10px; }

      &:after {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 130px;
        top: 60px; }

      .icon {
        position: absolute;
        right: 100px;
        top: 20px;
        opacity: 0.6;

        img {
          width: 90px; } }

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } } }

  .page_con {
    width: 100%;
    max-width: 1100px;
    display: flex;
    position: relative;
    z-index: 2;
    margin: 0 auto; }

  .page_card {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px 20px;
    width: 100%;
    min-height: 200px;

    .top_data {
      display: flex;
      align-items: center;
      justify-content: center;

      .video {
        flex: 0 0 500px;
        width: 500px;
        // height: 3.33rem
        position: relative;
        border-radius: 0.1rem;
        overflow: hidden;
        background: #fff;

        video {
          width: 100%;
          height: 100%; } }

      .data {
        flex: 1;
        margin-left: 30px;

        .title {
          font-size: 24px;
          color: #333333;
          line-height: 1.8;
          font-weight: bold; }

        .h2 {
          font-size: 16px;
          color: #333333;
          margin-top: 20px; }

        p {
          font-size: 14px;
          color: #4f5157;
          line-height: 1.7;
          margin-top: 10px; }

        .mini {
          margin-top: 16px;

          p {
            font-size: 14px;
            color: #999999;
            margin-top: 16px;
            line-height: 1;
            display: flex;
            align-items: center;

            &.pc_show {
              display: flex; }

            &.phone_show {
              display: none; }

            img {
              margin-right: 6px; }

            &.blue {
              span {
                font-size: 22px;
                color: #0F77FF; } } } } } }

    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .tab {
        line-height: 1;
        padding: 10px 20px;
        font-size: 16px;
        color: #999999;
        margin: 0 15px;
        cursor: pointer;

        &.active {
          background: #0F77FF;
          border-radius: 20px;
          color: #fff; } } }

    .m_cards {
      display: flex;
      align-items: flex-start;
      flex-flow: row wrap;
      margin-top: 30px;

      .card {
        flex: 0 0 250px;
        width: 250px;
        margin-bottom: 30px;
        border-radius: 10px;
        background: #FFFFFF;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        cursor: pointer;
        line-height: 1;
        position: relative;

        &:not(:nth-child(4n+1)) {
          margin-left: 20px; }

        // &:hover
        // .bottom
        // white-space: normal
        .img {
          width: 250px;
          height: 160px;
          background: #eee;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            min-height: 100%;
            // height: 100%
            object-fit: cover; }

          .video_play {
            position: absolute;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;

            &::after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 17px solid #fff;
              position: relative;
              left: 2px; } } }

        .title {
          font-size: 16px;
          color: #333333;
          width: 100%;
          margin-top: 10px;
          line-height: 1.5; }

        .bottom {
          width: 100%;
          font-size: 14px;
          color: #999999;
          margin-top: 8px;
          overflow: hidden;
          line-height: 1.7;
          // text-overflow: ellipsis
 }          // white-space: nowrap

        .author {
          font-size: 12px;
          margin-top: 10px;
          width: 100%;
          color: #999; } } } }

  .load_more {
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    &.active {
      color: #ccc;
      cursor: default; } }

  @media screen and (max-width: 1200px) {
    .title_con {
      .info {
        padding: 0 20px;
        width: auto; } }

    .page_card {
      .m_cards {
        .card {
          flex: 0 0 23%;
          width: 23%;

          &:not(:nth-child(4n+1)) {
            margin-left: 2.5%; }

          .img {
            width: 100%; } } } } }

  @media screen and (max-width: 1000px) {
    .page_card {
      .top_data {
        flex-flow: row wrap;

        .video {
          flex: 0 0 100%;
          width: 100%; }

        .data {
          flex: 0 0 100%;
          width: 100%;
          text-align: center;
          margin-left: 0;
          margin-top: 10px;

          .title {
            font-size: 18px; }

          .h2 {
            font-size: 14px; }

          .mini {
            p {
              justify-content: center;

              &.pc_show {
                display: none; }

              &.phone_show {
                display: flex; } } } } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;

    .page_card {
      .tabs {
        flex-flow: row wrap;

        .tab {
          margin: 5px;
          padding: 5px 10px;
          white-space: nowrap; } }

      .m_cards {
        .card {
          flex: 0 0 48%;
          width: 48%;

          &:not(:nth-child(4n+1)) {
            margin-left: 0; }

          &:not(:nth-child(2n+1)) {
            margin-left: 3%; }

          .img {
            width: 100%; } } } } } }
