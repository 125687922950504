.address_model {
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  .title {
    font-size: 18px;
    color: #39475B; }

  .info {
    font-size: 14px;
    color: #8998AC;
    line-height: 22px;
    margin-top: 10px; }

  .line {
    margin-top: 20px;
    .ant-input {
      font-size: 14px; }
    .ant-checkbox + span {
      font-size: 14px;
      color: #8998AC; } } }

.mallcreatorder_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .con {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0 20px; }

    .add_title {
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.mt {
        margin-top: 20px; }

      .btn {
        font-size: 12px;
        color: #0F77FF;
        cursor: pointer; } }

    .addlist {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-top: 15px;

      .add {
        flex: 0 0 24%;
        width: 24%;
        height: 120px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        margin-right: 1%;
        border-radius: 10px;
        padding: 10px;
        border: 1px dashed #ccc;
        opacity: 0.8;
        margin-bottom: 10px;
        cursor: pointer;

        &.active {
          border: 1px dashed #0F77FF;
          opacity: 1; }

        &.default {
          .default {
            display: block; } }

        .default {
          display: none;
          position: absolute;
          right: 5px;
          top: 6px;
          line-height: 1;
          border-radius: 20px;
          font-size: 12px;
          color: #fff;
          background-color: #999;
          padding: 5px 10px; }

        .title {
          font-size: 14px;
          line-height: 1;
          color: #333333;
          width: 100%;
          border-bottom: 1px solid #ddd;
          padding: 0 0 10px; }

        .info {
          margin-top: 8px;
          font-size: 12px;
          line-height: 20px;
          height: 40px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #666; }

        .btn {
          font-size: 12px;
          color: #D44242;
          cursor: pointer;
          margin-top: 5px; } } }

    .more_add {
      font-size: 12px;
      color: #333333;
      margin-top: 5px;
      cursor: pointer; }

    .bg_card {
      background: #F8F8F8;
      border-radius: 10px;
      padding: 20px;
      margin-top: 15px;

      .goods {
        .good {
          padding: 0 0 20px;
          display: flex;
          align-items: center;

          .poster {
            flex: 0 0 100px;
            width: 100px;
            height: 100px;
            background: #fff;

            img {
              width: 100%;
              height: 100%; } }

          .infos {
            flex: 1;
            margin-left: 20px;

            .t {
              font-size: 18px;
              color: #333333; }

            .c {
              font-size: 14px;
              color: #999999;
              margin-top: 8px; }

            .b {
              font-size: 14px;
              margin-top: 8px;
              color: #333333;

              span {
                font-size: 18px; } } } } }

      .num {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #333333; }

      .mark {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #333333;

        .input {
          flex: 1;
          margin-left: 10px;

          input {
            width: 100%;
            height: 30px;
            padding: 0 5px;
            border-radius: 4px; } } }

      .integral {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;
        color: #333333;

        span {
          color: #FF6900;

          &.big {
            font-size: 24px; } } }

      .title {
        font-size: 18px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .r {
          font-size: 16px; } }

      .b {
        font-size: 12px;
        color: #999999; } }

    .settlement {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .btn {
        cursor: pointer;
        padding: 0 40px;
        background: #0F77FF;
        border-radius: 24px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px; } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 20px;
      .p_title {
        span {
          margin-left: 0; } }
      .con {
        padding: 0; } }

    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } } }

  @media screen and (max-width: 660px) {
    padding-top: 90px;
    .page_con {
      .addlist {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-top: 15px;

        .add {
          flex: 0 0 49%;
          width: 49%;
          height: 120px; } } } } }
