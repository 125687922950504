.scienceclass_page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;

  .tabs {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 40px;
    width: 960px;
    margin: 40px auto 0;
    position: relative;

    .tab {
      line-height: 1;
      padding: 10px 20px;
      font-size: 16px;
      color: #999999;
      cursor: pointer;
      flex: 0 0 20%;
      width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active {
        background: #0F77FF;
        border-radius: 20px;
        color: #fff; }

      &.more {
        color: #0F77FF;
        text-align: right; }

      &.back {
        color: #0F77FF;
        position: absolute;
        right: 0;
        bottom: -30px; } } }

  .card_list {
    width: 960px;
    margin: 0 auto;
    margin-top: 55px;
    display: flex;
    flex-flow: row wrap;

    .nodata {
      text-align: center;
      width: 100%; }

    .card {
      width: 300px;
      height: 250px;
      margin-bottom: 30px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0 10px 80px 0 rgba(29, 42, 67, 0.06);
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;
      margin-left: 30px;

      &:nth-child(3n+1) {
        margin-left: 0; }

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(29, 42, 67, 0.1); }

      .img {
        line-height: 0;
        width: 260px;
        height: 146px;
        position: relative;
        background: #eee;

        img {
          width: 260px;
          height: 146px; }

        .play_btn {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          img {
            width: 100%;
            height: 100%; } } }

      .title {
        font-size: 16px;
        line-height: 1.4;
        color: #4E5053;
        margin-top: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; }

      .info {
        font-size: 14px;
        line-height: 1.7;
        color: #A2A9B3;
        text-align: center;
        margin-top: 14px;
        max-width: 180px; }

      .download {
        font-size: 13px;
        line-height: 1.5;
        color: #A2A9B3;
        margin-top: 13px;
        display: flex;
        align-items: center;

        i.icon {
          display: inline-block;
          width: 18px;
          height: 12px;
          background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
          background-size: 18px 12px; }

        span {
          margin-left: 6px; } } } }
  .load_more {
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    &.active {
      color: #ccc;
      cursor: default; } }

  @media screen and (max-width: 1000px) {
    .card_list {
      width: 100%;
      padding: 0 10px;

      .card {
        flex: 0 0 30%;
        width: 30%;
        padding: 20px 0;
        height: auto;

        .img {
          width: 94%;
          height: auto;

          img {
            width: 100%;
            height: auto; } } } }

    .tabs {
      width: 100%;
      padding: 0 10px;

      .tab {
        margin: 5px;
        padding: 5px 10px;
        white-space: nowrap;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; } } }

  @media screen and (max-width: 680px) {
    .card_list {
      .card {
        margin-left: 20px; } } }

  @media screen and (max-width: 640px) {
    padding-top: 60px;

    .card_list {
      padding: 0;
      width: 100%;
      justify-content: center;

      .card {
        flex: 0 0 300px;
        width: 300px;
        padding: 20px 0;
        height: auto;
        margin-left: 0;

        .img {
          width: 260px;
          min-height: 100px;

          img {
            width: 100%;
            height: auto; } } } } }

  .swiper-container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(65deg, #6AECFF 0%, #3B67DD 50%, #3147D0 100%);

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden; } }

  .banner {
    width: 100%;
    height: 410px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    max-width: 100%;

    .bg {
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 800px;
      transform-origin: 0 100%;
      background-image: linear-gradient(65deg, #6AECFF 0%, #3B67DD 50%, #3147D0 100%);
      transition: all 0.2s ease;
      width: 100%;
      transform: translateX(-50%) rotate(0);
      border-radius: 0;

      img {
        height: 410px;
        position: absolute;
        bottom: 0;
        transform-origin: 0 100%;
        transition: all 0.2s ease;
        left: 50%;
        transform: translate(-50%, 0) rotate(0); } }

    .content {
      width: 960px;
      position: relative;
      top: 25px;
      z-index: 2;

      &::before, &::after {
        display: block;
        content: '';
        width: 60px;
        height: 48px;
        background: url('../../assets/img/quote_icon.png') 0 0 no-repeat;
        background-size: 60px 48px;
        position: absolute;
        left: -40px;
        top: -10px; }

      &::after {
        transform: rotate(180deg) scale(0.7);
        left: 250px;
        top: auto;
        bottom: 60px; }

      .title {
        color: #fff;
        font-size: 38px;
        line-height: 1.6;
        margin-bottom: 10px; }

      p {
        color: #fff;
        font-size: 14px;
        line-height: 2;

        &.big {
          font-size: 16px;
          margin-top: 5px; } }

      .btn {
        width: 110px;
        height: 48px;
        margin-top: 30px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        background: #0F77FF;
        border-radius: 24px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

        &.yellow {
          background: #e79335; } } } }


  @media screen and (max-width: 640px) {
    .banner {
      height: 410px;

      .bg {
        img {
          height: 410px; } }

      .content {
        padding: 0 40px;

        .title {
          font-size: 22px; }

        p {
          font-size: 12px; }

        .btn {
          width: 80px;
          height: 40px; } } } }

  @media screen and (max-width: 1000px) {
    .banner {
      .content {
        padding: 0 40px; } } } }
