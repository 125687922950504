.certification_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      &.h2 {
        margin-top: 30px;

        span {
          font-size: 22px;
          font-weight: normal; } }

      &.h3 {
        span {
          font-size: 14px;
          color: #999999;
          font-weight: normal; } }

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .con {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0 20px; }

    .selects {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      padding-bottom: 30px;

      .card {
        width: 300px;
        height: 432px;
        overflow: hidden;
        margin: 20px 30px 0;
        background: #FFFFFF;
        box-shadow: 0 10px 24px 3px rgba(0, 0, 0, 0.06);
        border-radius: 15px;
        border: 1px solid #fff;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        cursor: pointer;

        &.disabled {
          filter: gray;
          filter: grayscale(1);
          pointer-events: all; }

        .img {
          width: 344px;
          height: 315px;

          img {
            width: 100%;
            height: 100%; } }

        &:hover {
          border: 1px solid #0E77FF;
          box-shadow: 0 10px 24px 3px rgba(0, 0, 0, 0.1); } } }

    .step2 {
      display: flex;
      align-items: flex-start;
      padding: 0 20px;
      max-width: 960px;
      width: 100%;
      margin: 20px auto 0;

      .l {
        flex: 0 0 50%;
        width: 50%; }

      .r {
        flex: 0 0 40%;
        width: 40%;

        img {
          width: 100%; } } }

    .step3 {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      max-width: 960px;
      width: 100%;
      margin: 20px auto 0;

      .h1 {
        font-size: 24px;
        color: #333333;
        margin-top: 30px; }

      .p {
        font-size: 14px;
        color: #999999;
        margin-top: 12px;
        margin-bottom: 30px; }

      .icon {
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%; } } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 20px; } }

  @media screen and (max-width: 660px) {
    padding-top: 90px; } }
