.lecture_info_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(180deg, #D93B06 0%, #FE6200 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before {
        display: block;
        content: '';
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 20px;
        top: 10px; }

      &:after {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 130px;
        top: 60px; }

      .icon {
        position: absolute;
        right: 100px;
        top: 20px;
        opacity: 0.6;

        img {
          width: 130px; } }

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } } }

  .card {
    background: #FFFFFF;
    box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);
    border-radius: 8px;
    padding: 20px 20px 20px 40px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    .info_img {
      width: 100%;
      line-height: 0; }

    &.mt {
      margin-top: 20px; }

    .content {
      display: flex;

      .logo {
        flex: 0 0 200px;
        width: 200px;
        padding-top: 20px;

        img {
          width: 180px; } }

      .txt {
        flex: 1;
        margin-left: 30px;

        .t {
          font-size: 24px;
          color: #333333;
          line-height: 1;
          padding-bottom: 15px;
          position: relative;

          &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            display: inline-block;
            width: 40px;
            border-bottom: 2px solid #0F77FF; } }

        .p {
          font-size: 14px;
          color: #999999;
          margin-top: 15px;
          line-height: 1.7; } } } }

  @media screen and (max-width: 1200px) {
    .title_con {
      .info {
        padding: 0 20px;
        width: auto; } }

    .card {
      .content {
        flex-flow: column nowrap;

        .logo {
          flex: 0 0 auto; }

        .txt {
          margin-left: 0;
          margin-top: 20px; } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px; } }
