.vos_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.2s ease;
    opacity: 0; }

  ._content {
    width: 800px;
    min-height: 100px;
    // border-radius: 10px
    // overflow: hidden
    // background: #fff
    position: absolute;
    z-index: 2;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    left: 50%;
    top: 50%;
    transition: all 0.2s ease;
    opacity: 0;
    transform: translate(-50%, -100%);

    .close {
      position: absolute;
      z-index: 999;
      right: 15px;
      top: 15px;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      overflow: hidden;
      opacity: 0.8;
      transition: all 0.2s ease;
      cursor: pointer;

      &::before, &::after {
        display: block;
        content: '';
        width: 24px;
        height: 3px;
        background: #666;
        position: absolute;
        left: 50%;
        top: 50%; }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg); }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg); }

      &:hover {
        opacity: 1;
        transform: rotate(90deg) scale(1.05); } }

    img {
      max-width: 100%; } }

  &.show {
    ._content {
      opacity: 1;
      transform: translate(-50%, -50%); }

    .mask {
      opacity: 1; } }

  @media screen and (max-width: 800px) {
    ._content {
      width: 90%;

      .close {
        top: 5px;
        right: 5px; } } } }
