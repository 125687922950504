.editinfo_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .con {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0 20px;

      .l {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .img {
          width: 150px;
          height: 150px;
          background: #eee;
          line-height: 0;
          margin-bottom: 10px;

          img {
            width: 100%; } } } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 20px; } }

  @media screen and (max-width: 660px) {
    padding-top: 90px; } }
