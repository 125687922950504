.lecture_videos_page {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .title_con {
    height: 98px;
    width: 100%;
    position: relative;

    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      height: 198px;
      width: 100%;
      background-image: linear-gradient(180deg, #2F1DB0 0%, #5342C7 100%); }

    .info {
      position: relative;
      z-index: 1;
      width: 1100px;
      height: 98px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before {
        display: block;
        content: '';
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 20px;
        top: 10px; }

      &:after {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.06);
        position: absolute;
        left: 130px;
        top: 60px; }

      .icon {
        position: absolute;
        right: 100px;
        top: 20px;
        opacity: 0.6;

        img {
          width: 100px; } }

      .l {
        display: flex;
        flex-flow: column nowrap;

        .t {
          font-size: 28px;
          color: #fff; }

        .b {
          font-size: 14px;
          color: #fff; } }

      .r {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } } }

  .card {
    background: #FFFFFF;
    box-shadow: 0 10px 90px 0 rgba(29, 42, 67, 0.05);
    border-radius: 8px;
    padding: 20px 20px 20px 40px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.mt {
      margin-top: 20px; }

    .lives {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;

      .live {
        flex: 0 0 240px;
        width: 240px;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;

        &:not(:nth-child(4n)) {
          margin-right: 20px; }

        .date {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 10px; }

        .poster {
          width: 240px;
          height: 135px;
          background: #eee;
          line-height: 0;
          margin-top: 10px;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%; } }

        .userinfo {
          height: 26px;
          font-size: 12px;
          color: #333333;
          display: flex;
          align-items: center;
          line-height: 1;
          width: 100%;
          padding-left: 15px;

          .headimg {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            background: #ddd;
            border: 1px solid #fff;
            margin-right: 10px;
            position: relative;
            top: -8px;

            img {
              width: 100%;
              height: 100%; } } }

        .live_title {
          width: 100%;
          margin-top: 10px;
          font-size: 16px;
          height: 18px;
          color: #333333;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }

        .live_info {
          width: 100%;
          margin-top: 6px;
          height: 16px;
          font-size: 14px;
          color: #999999;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }

        .status {
          width: 100%;
          margin-top: 10px;

          .btn {
            font-size: 14px;
            color: #FFFFFF;
            line-height: 1;
            padding: 5px 15px;
            display: inline-block;
            border-radius: 20px;
            background: #ccc;

            &.red {
              background: #D44242; }

            &.orange {
              background: #FC9301; }

            &.blue {
              background: #0F77FF;
              padding: 8px 20px; } } } } }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 20px; } }

  @media screen and (max-width: 1200px) {
    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } }
    .card {
      .lives {
        flex-flow: row wrap;

        .live {
          flex: 0 0 23%;
          width: 23%;

          &:not(:nth-child(4n)) {
            margin-right: 2.5%; }

          .poster {
            width: 100%;
            height: auto; } } } } }

  @media screen and (max-width: 660px) {
    padding-top: 60px;

    .card {
      padding: 20px;
      .lives {
        flex-flow: row wrap;

        .live {
          flex: 0 0 100%;
          width: 100%;
          margin-right: 0 !important;
          margin-bottom: 20px;

          .poster {
            width: 100%;
            height: auto; } } } } } }
