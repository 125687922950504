html, body {
  font-family: 'Microsoft YaHei', arial, sans-serif, 'Droid Sans Fallback';
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  background: #fff;
  position: relative; }

html, body, div, a, li, h1, h2, h3, h4, h5, p, input, textarea {
  padding: 0;
  margin: 0;
  font-weight: normal;
  -webkit-tap-highlight-color: transparent;
  // color: #333
  box-sizing: border-box; }

a {
  color: #3494ff;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent; }

i {
  font-style: normal; }

input, textarea {
  outline: none;
  border: 0;
  -webkit-appearance: none; }

input[type='search'] {
  -webkit-appearance: none; }

input[disabled], input:disabled {
  background: #fff; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #999; }

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #999; }

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #999; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #999; }

input::-webkit-search-decoration, input::-webkit-search-cancel-button {
  display: none; }

button {
  background: transparent;
  outline: none;
  border: 0;
  -webkit-appearance: none; }

ul, li {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.medium-zoom-overlay {
  z-index: 0; }

#root {
  z-index: 0;
  position: relative; }

.txt_box {
  .txt_title {
    text-align: center;
    font-size: 20px;
    line-height: 1.5; }
  .txt_desc {
    text-align: center;
    margin-top: 10px;
    line-height: 1.5;
    color: #666;
    font-size: 12px; }
  .txt_h1 {
    font-size: 16px;
    color: #333;
    margin-top: 16px; }

  p {
    line-height: 1.7;
    font-size: 14px;
    margin-top: 10px;
    color: #666; } }

.video-react {
  .video-react-big-play-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); } }

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  .btn {
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 14px;
    color: #216bda;
    border: 1px solid #216bda; } }

.empty_state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
  .img {
    width: 300px;
    img {
      width: 100%; } }
  .txt {
    color: #999; } }

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 20px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    transition: all 0.2s ease; }
  .swiper-pagination-bullet-active {
    background: #007aff;
    width: 8px; }

  &.swiper-pagination-fraction {
    position: absolute;
    width: auto;
    display: inline-block;
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 0 10px;
    border-radius: 20px;
    right: 10px;
    left: auto; } }

.medium-zoom-image--opened {
  object-fit: contain; }

.prism-big-play-btn {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: 50%;
  position: absolute; }

.setting-content {
  span {
    color: #fff; } }

.prism-setting-selector {
  div, span {
    color: #fff; } }

.prism-setting-item.prism-setting-cc, .prism-setting-item.prism-setting-audio {
  display: none; }

@keyframes bounce {
  from, 20%, 53%, 80%, to {}
  animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transform: translate3d(0,0,0);

  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -30px, 0); }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -15px, 0); }

  90% {
    transform: translate3d(0,-4px,0); } }
