.search_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .content {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      margin-top: 30px; }

    .search_bar {
      max-width: 420px;
      width: 100%;
      background: #F4F4F4;
      border-radius: 22px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 20px;

      .input {
        flex: 1;

        input {
          width: 100%;
          background: transparent;
          font-size: 18px;
          color: #666; } }

      .btn {
        flex: 0 0 80px;
        width: 80px;
        background: #0F77FF;
        border-radius: 17px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;

        .icon {
          margin-right: 5px;

          img {
            width: 15px;
            height: 15px; } } } }

    .search_end_info {
      font-size: 16px;
      color: #999999;
      margin-top: 20px;

      span {
        color: #0F77FF; } }

    .list_box {
      margin-top: 30px;
      width: 100%;
      max-width: 800px;

      .list {
        width: 100%;
        background: #f2f2f2;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin-top: 15px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1); }

        .l {
          .t {
            font-size: 16px;
            color: #333333;

            span {
              color: #0F77FF; } }

          .b {
            font-size: 14px;
            color: #999999;
            margin-top: 5px; } }

        .download {
          font-size: 13px;
          line-height: 1.5;
          color: #A2A9B3;
          display: flex;
          align-items: center;

          i.icon {
            display: inline-block;
            width: 18px;
            height: 12px;
            background: url('../../assets/img/download_icon.png') 0 0 no-repeat;
            background-size: 18px 12px;
            margin-right: 10px; } } } }

    .pagination {
      margin-top: 30px; } }

  @media screen and (max-width: 660px) {
    padding-top: 90px; } }
