.order_model {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  &.no_pd {
    padding: 0; }
  .top {
    width: 100%;
    height: 150px;
    position: relative;
    z-index: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    padding: 20px;
    .bg {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%; } }
    .t_tip {
      position: relative;
      z-index: 2;
      color: #fff; }
    .t_title {
      position: relative;
      z-index: 2;
      color: #fff;
      font-size: 24px;
      margin-top: 20px; }
    .t_info {
      position: relative;
      z-index: 2;
      color: #fff; } }

  .bottom {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .l {
    img {
      max-width: 140px; } }
  .r {
    margin-left: 20px; }
  .code {
    font-size: 30px; }

  .title {
    font-size: 18px;
    color: #39475B; }

  .bg_card {
    background: #F8F8F8;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;

    .good {
      display: flex;
      align-items: center;
      padding: 0 0 20px;
      border-bottom: 1px solid #eee;

      .poster {
        flex: 0 0 72px;
        width: 72px;
        height: 72px;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain; } }

      .infos {
        flex: 1;
        margin-left: 20px;

        .t {
          font-size: 14px;
          color: #333333; }

        .b {
          font-size: 14px;
          margin-top: 8px;
          color: #666; } } }

    .xuefen_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      font-size: 12px;
      color: #333333; }

    .text_right {
      text-align: right;
      margin-top: 10px;
      font-size: 16px;
      color: #333333;

      span {
        color: #FF6900; } }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 30px;
      color: #333333;

      .r {
        color: #999; } }

    .address {
      display: flex;
      align-items: center;

      .icon {
        flex: 0 0 46px;
        width: 46px;
        height: 46px;

        img {
          width: 100%;
          height: 100%; } }

      .r {
        margin-left: 15px;

        .c {
          font-size: 14px;
          color: #333333;
          line-height: 1.7; }

        .t {
          font-size: 14px;
          color: #333333;

          span {
            font-size: 12px;
            color: #999999; } }

        .b {
          font-size: 12px;
          color: #333333; } } } } }


@media screen and (max-width: 660px) {
  .order_model {
    .bottom {
      flex-flow: column nowrap;
      .r {
        margin-left: 0;
        margin-top: 10px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center; } } } }
.mallorderlist_page {
  min-height: 100vh;
  position: relative;
  padding-top: 130px;
  padding-bottom: 60px;
  background: #f2f2f2;

  .page_con {
    width: 100%;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background: #fff;
    padding: 30px 0;
    border-radius: 10px;

    .p_title {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px; }

      .more {
        height: 24px;
        padding: 0 20px;
        cursor: pointer;
        background: #F7F7F7;
        border: 1px solid #CDCDCD;
        border-radius: 12px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
        text-align: center;
        margin-left: 14px; } }

    .con {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0 20px; }

    .bg_card {
      background: #F8F8F8;
      border-radius: 10px;
      padding: 20px;
      margin-top: 15px;

      .goods {
        .good {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            padding: 0 0 20px; }

          .poster {
            flex: 0 0 100px;
            width: 100px;
            height: 100px;
            background: #fff;

            img {
              width: 100%;
              height: 100%; } }

          .infos {
            flex: 1;
            margin-left: 20px;

            .t {
              font-size: 18px;
              color: #333333; }

            .c {
              font-size: 14px;
              color: #999999;
              margin-top: 8px; }

            .b {
              font-size: 14px;
              margin-top: 8px;
              color: #333333;

              span {
                font-size: 18px; } } }

          .btn {
            font-size: 16px;
            color: #0F77FF;
            cursor: pointer;
            margin-left: 12px; } } } }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 20px;

      span {
        &.blue {
          cursor: pointer;
          color: #0F77FF; } } } }

  @media screen and (max-width: 1100px) {
    .page_con {
      width: 100%;
      padding: 20px; }

    .title_con {
      .info {
        width: 100%;
        padding: 0 20px; } } }

  @media screen and (max-width: 660px) {
    padding-top: 90px;
    .page_con {
      .bg_card {
        .goods {
          .good {
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: center;
            .infos {
              margin-left: 0;
              margin-top: 5px;
              .c, .b {
                margin-top: 0; } }
            .btn {
              margin-left: 0;
              margin-top: 5px; } } } } } } }
